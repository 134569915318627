import i18n from '../utils/i18n';

export const googleMapsKey = 'AIzaSyBxEwtMRXpcXdcMScNSUAF6G6sr9wwf3cA';
export const reCaptchaKey = '6LfaVK8qAAAAAHHKWSpZwTIZSFlh5FKvdfBahY06';

export const cdnUrl = 'https://www.genesis-club.co.il';
export const baseURL = cdnUrl + '/backend';
export const blobUrl = cdnUrl + '/backend/s3/prod.beprotravel/cdn/';

export const sortHotelOptions = [
  { value: 'recommended', label: 'sort.recommended' },
  { value: 'priceIncrease', label: 'sort.price_increase' },
  { value: 'priceDecrease', label: 'sort.price_decrease' },
  { value: 'starIncrease', label: 'sort.star_increase' },
  { value: 'starDecrease', label: 'sort.star_decrease' },
  { value: 'nameIncrease', label: 'sort.hotel_increase' },
  { value: 'nameDecrease', label: 'sort.hotel_decrease' },
  { value: 'distance', label: 'sort.distance' },
];

export const getGender = () => {
  return [
    { value: '0', label: i18n.t('male') },
    { value: '1', label: i18n.t('female') },
  ];
};

export const imgsCar = [
  require('../assets/img/vehicles/Private-Car-Regular.png'),
  require('../assets/img/vehicles/Private-Car-Luxury.png'),
  require('../assets/img/vehicles/Minivan-Shuttle.png'),
  require('../assets/img/vehicles/Minibus.png'),
  require('../assets/img/vehicles/Bus.png'),
  require('../assets/img/vehicles/Private-Car-Business.png'),
  require('../assets/img/vehicles/Minivan-Regular.png'),
  require('../assets/img/vehicles/Handicap.png'),
  require('../assets/img/vehicles/Private-Driver.png'),
  require('../assets/img/vehicles/Private-Car-Regular.png'),
  require('../assets/img/vehicles/Limousine.png'),
];

export const mapSupplier = 'google' as 'google' | 'osm';
export const sharedConfig = { cdnUrl };
export const flightConfig = {
  blobUrl,
  comodoImg: require('../assets/img/comodo.png'),
  imgPaymentLoader: require('../assets/img/card_pay.gif'),
  cdnUrl,
  needToScreenshot: true,
};
export const transferConfig = {
  mapSupplier,
  imgWaypointA: require('../assets/img/waypoint-a.png'),
  imgWaypointB: require('../assets/img/waypoint-b.png'),
  imgPaymentLoader: require('../assets/img/card_pay.gif'),
  imgsCar,
  cdnUrl,
  needToScreenshot: true,
};
export const packageConfig = {
  blobUrl,
  mapSupplier,
  imgCenterPoint: require('../assets/img/center.png'),
  imgDefaultBg: require('../assets/img/newbg.jpg'),
  imgPaymentLoader: require('../assets/img/card_pay.gif'),
  cdnUrl,
  needToScreenshot: true,
  imgDefaultAviaLogo: require('../assets/img/plane.png'),
};
export const dynConfig = {
  imgsCar,
  blobUrl,
  sortHotelOptions: sortHotelOptions.slice(0, -1),
  cdnUrl,
  needToScreenshot: true,
  imgLoaderMain: require('../assets/img/loader_genesis.png'),
  imgPaymentLoader: require('../assets/img/card_pay.gif'),
};

export const hotelConfig = {
  googleMapsKey,
  mapSupplier,
  sortOptions: sortHotelOptions,
  cdnUrl,
  imgBgImg: require('../assets/img/newbg.jpg'),
  imgBluePoint: require('../assets/img/blue.png'),
  imgRedPoint: require('../assets/img/red.png'),
  imgWaypointA: require('../assets/img/waypoint-a.png'),
  imgWaypointB: require('../assets/img/waypoint-b.png'),
  imgCenterPoint: require('../assets/img/center.png'),
  imgPaymentLoader: require('../assets/img/card_pay.gif'),
  needToScreenshot: true,
};

export enum SearchBoxTabs {
  flight = 'FLT',
  package = 'PAC',
  tours = 'TOR',
  hotel = 'HTL',
  transfer = 'TRA',
  dynamicFHS = 'FHS',
  dynamicFH = 'FLH',
}
