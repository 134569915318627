import { createContext } from 'react';
import { User } from '../ts/interfaces/User';
import { PageFooter, PageHeader, SiteConfig } from '../ts/interfaces/CmsPageResponse';

export const MainContext = createContext({
  proposal: {} as any,
  setProposal: (_proposals: any) => {},
  mapSupplier: 'google' as 'google' | 'osm',
  user: undefined as User | undefined,
  setUser: (_user?: User) => {},
  header: undefined as PageHeader | undefined,
  setHeader: (_header?: PageHeader) => {},
  footer: undefined as PageFooter | undefined,
  setFooter: (_footer?: PageFooter) => {},
  siteConfig: undefined as SiteConfig | undefined,
  setSiteConfig: (_siteConfig?: SiteConfig) => {},
  language: 'he' as string,
  setLanguage: (_language: string) => {},
});
