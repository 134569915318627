import React, { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'rc-checkbox/assets/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles/variables.scss';
import '@bepro-travel/fe.shared/dist/styles.css';
import '@bepro-travel/fe.hotel/dist/styles.css';
import '@bepro-travel/fe.flight/dist/styles.css';
import '@bepro-travel/fe.package/dist/styles.css';
import '@bepro-travel/fe.dynamic/dist/styles.css';
import '@bepro-travel/fe.transfer/dist/styles.css';

import './styles/genesis.scss';
import './styles/search.scss';
import './styles/genesis-package.scss';

import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { configureAxios } from './services/config';
import { MainContext } from './contexts/MainContext';
import { ToastContainer } from 'react-toastify';
import { i18nconfig } from './utils/i18n';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  cdnUrl,
  dynConfig,
  flightConfig,
  hotelConfig,
  mapSupplier,
  packageConfig,
  reCaptchaKey,
  transferConfig,
} from './constants/generic';
import { User } from './ts/interfaces/User';
import { FlightConfigContext } from '@bepro-travel/fe.flight';
import { HotelConfigContext } from '@bepro-travel/fe.hotel';
import { TransferConfigContext } from '@bepro-travel/fe.transfer';
import { PackageConfigContext } from '@bepro-travel/fe.package';
import { DynConfigContext } from '@bepro-travel/fe.dynamic';
import { PageFooter, PageHeader, SiteConfig } from './ts/interfaces/CmsPageResponse';
import { getLoadings, getPage, Proposal, ScriptLoader } from '@bepro-travel/fe.shared';
import { SharedConfigContext, auth } from '@bepro-travel/fe.shared';
import { SearchHotelModel } from './ts/classes/SearchHotelModel';
import SearchBox from './components/SearchBox';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  const [proposal, setProposal] = useState<Proposal>({
    hotels: [],
    flights: [],
    dynamic: [],
    ilPackages: [],
    transfers: [],
  });
  const [user, setUser] = useState<User | undefined>();
  const [header, setHeader] = useState<PageHeader | undefined>();
  const [footer, setFooter] = useState<PageFooter | undefined>();
  const [siteConfig, setSiteConfig] = useState<SiteConfig | undefined>(undefined);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'he');
  const [isAppReady, setIsAppReady] = useState(false);
  const [historyHotel, setHistoryHotel] = useState<SearchHotelModel[]>([]); // eslint-disable-line
  const [userSiteConfig, setUserSiteConfig] = useState({
    base: {
      openDetailsInNewTab: false,
    },
    hotel: {
      areRoomsGrouped: false,
      areRoomsColoured: false,
      isRoomNameCleared: true,
      areRoomsFiltered: true,
      isCubeDesign: false,
      showRoomsInTab: false,
      isShortMode: false,
      isCubeDesignByEat: false,
      displayMode: 0,
      roomMode: 0,
      oneHotelSearchByOneSupplier: false,
    },
  });

  const mainConfig = {
    proposal,
    setProposal,
    mapSupplier,
    user,
    setUser,
    header,
    setHeader,
    footer,
    setFooter,
    siteConfig,
    setSiteConfig,
    language,
    setLanguage,
  };

  const additinalConfig = {
    setFooter,
    setHeader,
    setSiteConfig,
    getPage,
  };

  const sharedConfig = {
    cdnUrl,
    user,
    setUser,
    footer,
    setFooter,
    header,
    setHeader,
    siteConfig,
    setSiteConfig,
    setLanguage,
    language,
    proposal,
    setProposal,
    imgUs: require('./assets/img/us.png'),
    imgIs: require('./assets/img/is.png'),
    imgBg: require('./assets/img/newbg.jpg'),
    imgLogo: require('./assets/img/logo.png'),
    imgPaymentLoader: require('./assets/img/card_pay.gif'),
    defaultMultiSearchConfig: 'FLT_PAC_TOR_HTL_TRA',
    SearchBox: SearchBox,
  };

  useEffect(() => {
    (async () => {
      configureAxios();
      const user = await auth.getUserToken();
      i18nconfig();
      setUser(user);

      setIsAppReady(true);

      await getLoadings();
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (language === 'he') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [language]);

  return (
    <BrowserRouter>
      <div className="app">
        {isAppReady && (
          <SharedConfigContext.Provider
            value={{
              ...sharedConfig,
              userSiteConfig,
              setUserSiteConfig,
            }}
          >
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
              <ScriptLoader>
                <FlightConfigContext.Provider value={{ ...flightConfig, ...additinalConfig }}>
                  <HotelConfigContext.Provider
                    value={{
                      ...hotelConfig,
                      user,
                      ...additinalConfig,
                      userSiteConfig,
                    }}
                  >
                    <TransferConfigContext.Provider
                      value={{ ...transferConfig, ...additinalConfig }}
                    >
                      <PackageConfigContext.Provider
                        value={{ ...packageConfig, ...additinalConfig, language }}
                      >
                        <DynConfigContext.Provider
                          value={{ ...dynConfig, ...additinalConfig, proposal, setProposal }}
                        >
                          <MainContext.Provider value={mainConfig}>
                            <Header />
                            <Router />
                            <Footer />
                          </MainContext.Provider>
                        </DynConfigContext.Provider>
                      </PackageConfigContext.Provider>
                    </TransferConfigContext.Provider>
                  </HotelConfigContext.Provider>
                </FlightConfigContext.Provider>
              </ScriptLoader>
            </GoogleReCaptchaProvider>
          </SharedConfigContext.Provider>
        )}
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
